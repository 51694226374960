/* eslint-disable no-unused-vars */
import Vue from "vue";
import {
  VMenu,
  VList,
  VListItem,
  VListItemGroup,
  VListItemContent,
  VListItemIcon,
  VListItemTitle,
  VIcon,
  VBtn,
  VDivider,
  VListItemSubtitle,
  VListItemAction,
} from "vuetify/lib";

import colors from "vuetify/lib/util/colors";

import { getSituacionIcon } from "@/utils/index.js";

export default function(event, item, instance, isSelected, select) {
  event.preventDefault();

  let auxColors = { ...instance.$vuetify.theme.currentTheme };
  Object.keys(auxColors).map(
    (key, index) => (auxColors[key] = { base: auxColors[key] })
  );
  const allColors = { ...colors, ...auxColors };

  const hexColor = (name) => {
    if (!name) return name;
    if (/^#(?:[0-9a-f]{3}){1,2}$/i.test(name)) return name;
    const [nameFamily, nameModifier] = name.split(" ");
    const shades = ["black", "white", "transparent"];
    const util = { family: null, modifier: null };
    if (shades.find((shade) => shade === nameFamily)) {
      util.family = "shades";
      util.modifier = nameFamily;
    } else {
      const [firstWord, secondWord] = nameFamily.split("-");
      util.family = `${firstWord}${
        secondWord
          ? secondWord.charAt(0).toUpperCase() + secondWord.slice(1)
          : ""
      }`;
      util.modifier = nameModifier ? nameModifier.replace("-", "") : "base";
    }
    return allColors[util.family][util.modifier];
  };

  const actions = [
    {
      actions: [
        {
          text: "Abrir detalles",
          icon: "mdi-eye",
          handler: () => (instance.detailsId = item.IdCarteraCobro),
        },
        {
          text: isSelected ? "Deseleccionar" : "Seleccionar",
          icon: !isSelected
            ? "mdi-checkbox-blank-outline"
            : "mdi-minus-box-outline",
          handler: () => select(!isSelected),
        },
        {
          text: "Link pago con tarjeta",
          icon: "mdi-credit-card-outline",
          handler: () => (instance.pagoTarjeta = [item]),
        },
        {
          text: "Ver pdf",
          icon: "mdi-file-document-edit-outline",
          handler: () => instance.verPDFfactura(item.IdDocumento),
        },
        {
          text: "Detalles cliente",
          icon: "mdi-account",
          handler: () =>
            instance.$router.push({
              name: "DetallesCliente",
              query: { idCliente: item.IdCliente },
            }),
        },
        {
          text: "Detalles contrato",
          icon: "mdi-file-document-outline",
          handler: () =>
            instance.$router.push({
              name: "DetallesContrato",
              query: {
                codigoContrato: item.CodigoContrato,
                idContrato: item.IdContrato,
              },
            }),
        },
        {
          text: "Ver mensajes",
          icon: "mdi-note-multiple-outline",
          handler: () =>
            instance.getMessages(item.IdCarteraCobro, item.IdCliente)
        },
      ],
    },
    {
      actions: [
        {
          text:
            instance.inlineFilters.Identificador != "=" + item.Identificador
              ? "Filtrar cliente"
              : "Quitar filtro cliente",
          subText: item.Identificador,
          icon:
            instance.inlineFilters.Identificador != "=" + item.Identificador
              ? "mdi-account-filter"
              : "mdi-account-remove",
          handler: () => {
            if (
              instance.inlineFilters.Identificador !=
              "=" + item.Identificador
            )
              instance.$set(
                instance.inlineFilters,
                "Identificador",
                "=" + item.Identificador
              );
            else instance.$delete(instance.inlineFilters, "Identificador");
          },
          actionIcon: "mdi-clipboard-outline",
          handlerAction: (e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(item.Identificador);
						instance.$root.$emit('snack', 'Copiado al portapapeles')
          },
        },
        {
          text:
            instance.inlineFilters.CodigoContrato != "=" + item.CodigoContrato
              ? "Filtrar contrato"
              : "Quitar filtro contrato",
          subText: item.CodigoContrato,
          icon:
            instance.inlineFilters.CodigoContrato != "=" + item.CodigoContrato
              ? "mdi-filter"
              : "mdi-filter-off",
          handler: () => {
            if (
              instance.inlineFilters.CodigoContrato !=
              "=" + item.CodigoContrato
            )
              instance.$set(
                instance.inlineFilters,
                "CodigoContrato",
                "=" + item.CodigoContrato
              );
            else instance.$delete(instance.inlineFilters, "CodigoContrato");
          },
          actionIcon: "mdi-clipboard-outline",
          handlerAction: (e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(item.CodigoContrato);
						instance.$root.$emit('snack', 'Copiado al portapapeles')
          },
        },
        {
          text:
            instance.inlineFilters.CanalAgente != "=" + item.CanalAgente
              ? "Filtrar canal"
              : "Quitar filtro canal",
          subText: item.CanalAgente,
          icon:
            instance.inlineFilters.CanalAgente != "=" + item.CanalAgente
              ? "mdi-filter"
              : "mdi-filter-off",
          handler: () => {
            if (instance.inlineFilters.CanalAgente != "=" + item.CanalAgente)
              instance.$set(
                instance.inlineFilters,
                "CanalAgente",
                "=" + item.CanalAgente
              );
            else instance.$delete(instance.inlineFilters, "CanalAgente");
          },
          actionIcon: "mdi-clipboard-outline",
          handlerAction: (e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(item.CanalAgente);
						instance.$root.$emit('snack', 'Copiado al portapapeles')
          },
        },
        {
          text:
            instance.inlineFilters.NumeroFacturaRoot !=
            "=" + item.NumeroFacturaRoot
              ? "Filtrar factura"
              : "Quitar filtro factura",
          subText: item.NumeroFacturaRoot,
          icon:
            instance.inlineFilters.NumeroFacturaRoot !=
            "=" + item.NumeroFacturaRoot
              ? "mdi-filter"
              : "mdi-filter-off",
          handler: () => {
            if (
              instance.inlineFilters.NumeroFacturaRoot !=
              "=" + item.NumeroFacturaRoot
            )
              instance.$set(
                instance.inlineFilters,
                "NumeroFacturaRoot",
                "=" + item.NumeroFacturaRoot
              );
            else instance.$delete(instance.inlineFilters, "NumeroFacturaRoot");
          },
          actionIcon: "mdi-clipboard-outline",
          handlerAction: (e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(item.NumeroFacturaRoot);
						instance.$root.$emit('snack', 'Copiado al portapapeles')
          },
        },
      ],
    },
    {
      actions: [
        {
          text: "Recargar",
          icon: "mdi-refresh",
          handler: () => instance.getImpagados(),
        },
      ],
    },
  ];

  const menuComp = Vue.component("context-menu", {
    render: function(h) {
      return h(
        VMenu,
        {
          props: {
            value: true,
            dense: true,
            positionX: event.pageX,
            positionY: event.pageY,
            position: "absolute",
            transition: "slide-y-transition",
            minWidth: "300",
          },
        },
        [
          h(
            VList,
            {
              props: { nav: true },
              style: `border-top: 5px solid ${hexColor(
                getSituacionIcon(item.situacionContrato).color
              )}`,
            },
            [
              actions.map((action) =>
                h(VListItemGroup, {}, [
                  ...action.actions.map((a) =>
                    h(
                      VListItem,
                      { props: { dense: true }, on: { click: a.handler } },
                      [
                        h(VListItemIcon, {}, [h(VIcon, a.icon)]),
                        h(VListItemContent, {}, [
                          h(VListItemTitle, {}, [a.text]),
                          h(VListItemSubtitle, {}, [a.subText]),
                        ]),
                        a.actionIcon
                          ? h(VListItemAction, {}, [
                              h(
                                VBtn,
                                {
                                  props: { icon: true, small: true },
                                  on: { click: a.handlerAction },
                                },
                                [h(VIcon, { props: {small: true} }, a.actionIcon)]
                              ),
                            ])
                          : null,
                      ]
                    )
                  ),
                  h(VDivider, { class: "mb-1" }),
                ])
              ),
              h(VListItem, { props: { dense: true } }, [
                h(VListItemTitle, { class: "d-flex justify-space-around" }, [
                  h(
                    VIcon,
                    {
                      class: "d-inline",
                      props: {
                        small: true,
                        disabled: !Number(item.carta1),
                        color: getSituacionIcon("1ª Carta").color,
                      },
                    },
                    "mdi-email-send-outline"
                  ),
                  h(
                    VIcon,
                    {
                      class: "d-inline",
                      props: {
                        small: true,
                        disabled: !Number(item.carta2),
                        color: getSituacionIcon("2ª Carta").color,
                      },
                    },
                    "mdi-email-send"
                  ),
                  h(
                    VIcon,
                    {
                      class: "d-inline",
                      props: {
                        small: true,
                        disabled: !Number(item.cartaCorte),
                        color: getSituacionIcon("Carta de Corte").color,
                      },
                    },
                    "mdi-content-cut"
                  ),
                ]),
              ]),
            ]
          ),
        ]
      );
    },
  });

  instance.contextMenuComp = menuComp;
}
