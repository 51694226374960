<template>
  <v-container fluid>
    <!-- <tarjeta-datos v-if="menuTab == 'actual'" header icon="map" titulo="Mapa de calor">
			<template v-slot:titulo>
				Mapa de calor
				<v-btn class="ml-auto" icon small @click.stop="showHeatMap = !showHeatMap">
					<v-icon v-if="showHeatMap == false">mdi-chevron-down</v-icon>
					<v-icon v-else>mdi-chevron-up</v-icon>
				</v-btn>
			</template>
			<v-expand-transition>
				<ImpagadosHeatGrid
					class="ma-2"
					v-if="showHeatMap"
					@changeIdCarteraCobro="v => detailsId = v"
					:headers="headersFiltrados"
					:filters="inlineFilters"
					:impagados="impagadosFiltrados"
				/>
			</v-expand-transition>
		</tarjeta-datos>-->

    <v-card class="d-flex flex-wrap align-center pa-2" style="gap: 10px">
      <v-subheader> <v-icon left small>mdi-filter</v-icon>Filtros </v-subheader>
      <v-divider vertical />
      <v-text-field
        style="max-width: 300px"
        full-width
        placeholder="Nombre/RS"
        prepend-inner-icon="mdi-account"
        filled
        dense
        hide-details
        :value="inlineFilters.denominacion"
        @input="(v) => $set(inlineFilters, 'denominacion', v)"
      ></v-text-field>
      <v-text-field
        style="max-width: 200px"
        full-width
        placeholder="DNI/CIF/NIE"
        prepend-inner-icon="mdi-card-account-details-outline"
        filled
        dense
        hide-details
        :value="inlineFilters.Identificador"
        @input="(v) => $set(inlineFilters, 'Identificador', v)"
      ></v-text-field>
      <v-text-field
        style="max-width: 200px"
        full-width
        placeholder="Factura"
        prepend-inner-icon="mdi-file-document-edit-outline"
        filled
        dense
        hide-details
        :value="inlineFilters.NumeroFacturaRoot"
        @input="(v) => $set(inlineFilters, 'NumeroFacturaRoot', v)"
      ></v-text-field>
      <v-text-field
        style="max-width: 200px"
        full-width
        placeholder="Contrato"
        prepend-inner-icon="mdi-file-document-edit-outline"
        filled
        dense
        hide-details
        :value="inlineFilters.CodigoContrato"
        @input="(v) => $set(inlineFilters, 'CodigoContrato', v)"
      ></v-text-field>

      <v-spacer />

      <v-badge
        color="error darken-2"
        :content="solicitudesCorte.sol.length"
        overlap
        :value="solicitudesCorte.sol.length > 0"
      >
        <v-btn
          @click.stop="solicitudesReenganche.show = false; solicitudesCorte.show = !solicitudesCorte.show"
          text
          :class="{'v-btn--active' : solicitudesCorte.show}"
          color="error darken-1"
        >
          <v-icon left>mdi-content-cut</v-icon>Cortes abiertos
        </v-btn>
      </v-badge>
      <v-badge
        color="#19c284"
        :content="solicitudesReenganche.sol.length"
        overlap
        :value="solicitudesReenganche.sol.length > 0"
      >
        <v-btn
          @click.stop="solicitudesCorte.show = false; solicitudesReenganche.show = !solicitudesReenganche.show"
          text
          :class="{'v-btn--active' : solicitudesReenganche.show}"
          color="#19c284"
        >
          <v-icon left>mdi-link</v-icon>
          Reenganches abiertos
        </v-btn>
      </v-badge>
    </v-card>

    <v-expand-transition>
      <SolicitudesCorte @reload="getSolicitudesCorte" v-if="solicitudesCorte.show" :solicitudes="solicitudesCorte.sol" @filter="(v) => v ? $set(inlineFilters, 'CodigoContrato', '=' + v) : $delete(inlineFilters, 'CodigoContrato')" />
    </v-expand-transition>
    <v-expand-transition>
      <SolicitudesReenganche @reload="getSolicitudesReenganche" v-if="solicitudesReenganche.show" :solicitudes="solicitudesReenganche.sol" @filter="(v) => v ? $set(inlineFilters, 'CodigoContrato', '=' + v) : $delete(inlineFilters, 'CodigoContrato')" />
    </v-expand-transition>

    <div class="d-flex flex-wrap py-2">
      <v-btn class="ma-1" color="primary" small @click="getImpagados">
        <v-icon left>mdi-reload</v-icon>Recargar
      </v-btn>

      <!-- <v-btn
				v-for="filtro in menuTab == 'actual' ? Object.keys(estadosFiltros) : []"
				:key="filtro"
				small
				text
				:class="{ 'ma-1': true, 'v-btn--active': estadosFiltros[filtro] }"
				:color="getSituacionIcon(filtro).color"
				@click.stop="estadosFiltros[filtro] = !estadosFiltros[filtro]"
				:disabled="impagados.filter(imp => imp.estadoPropio == filtro).length == 0"
			>
				<v-icon left>{{ getSituacionIcon(filtro).icon }}</v-icon>
				{{ impagados.filter(imp => imp.estadoPropio == filtro).length }} {{ filtro }}
			</v-btn>-->
      <btn-filtros
        v-if="menuTab == 'actual'"
        :items="impagados"
        v-model="estadosFiltros"
        filter-key="estadoPropio"
        @filter-by="v => inlineFilters.estadoPropio = v"
      ></btn-filtros>

      <v-spacer />
      <v-btn
        @click="cambiarEstado = true"
        color="primary"
        :disabled="selected.length == 0"
        text
        >Cambiar estado</v-btn
      >
      <v-btn
        v-if="$root.acceso('CONTROL_IMPAGADOS')"
        @click.stop="exportarTodos(selected)"
        text
        class="ma-1"
        color="primary"
        small
        :disabled="selected.length == 0"
      >
        <v-icon left>mdi-download</v-icon>Descargar seleccionados
      </v-btn>
    </div>

    <v-tabs v-model="menuTab" touchless>
      <v-tab :disabled="loading" :href="'#actual'">Impagados actuales</v-tab>
      <v-tab :disabled="loading" :href="'#historico'">Historico Impagados</v-tab>
      <v-spacer />
      <v-tab :disabled="loading" :href="'#plan_de_pagos'">PP</v-tab>
      <v-tab :disabled="loading" :href="'#gesico'">Gesico</v-tab>
      <v-tab :disabled="loading" :href="'#cesce'">Cesce</v-tab>
      <v-tab :disabled="loading" :href="'#monitorio'">Monitorio</v-tab>
      <v-spacer />
      <v-tab :disabled="loading" :href="'#fracTransferencia'">Fraccionamientos transferencia</v-tab>
      <v-tab :disabled="loading" :href="'#frac'">Fraccionamientos activos</v-tab>
    </v-tabs>

    <v-card>
      <v-data-table
        fixed-header
        :items="impagadosFiltrados"
        :headers="headersFiltrados"
        show-select
        class="fixed-checkbox"
        item-key="IdCarteraCobro"
        v-model="selected"
        :single-select="false"
        :page.sync="page"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        }"
        checkbox-color="secondary"
        @contextmenu:row="contextMenuImp"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="impagadosFiltrados"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.estado="{ item }">
          <status-chip class="mr-2" :value="item.estado" />
        </template>

        <template v-slot:item.NImpagadosCliente="{ item }">
          <v-chip
            label
            :color="
              getNImpagadosColor(
                ['info', 'warning', 'deep-orange', 'error', '#d00000'],
                item.NImpagadosCliente
              )
            "
            outlined
            small
            class="pa-2 mr-1 v-chip--active"
            >{{ item.NImpagadosCliente }}</v-chip
          >
					<v-tooltip v-if="Number(item.historialImpagos)" bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-icon :color="getNImpagadosColor(
                ['info', 'warning', 'deep-orange', 'error', '#d00000'],
                item.historialImpagos
              )" v-bind="attrs" v-on="on" small>mdi-timer-sand</v-icon>
							</template>
							Historial impagos: {{ item.historialImpagos }}
						</v-tooltip>
        </template>

        <template v-slot:item.info="{ item }">
          <div class="d-flex align-center">
            <v-tooltip right>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                label
                small
                text
                :color="item.colorCliente"
                :class="{
                  'pa-1 font-weight-bold': true,
                  'v-btn--active':
                    inlineFilters.Identificador == '=' + item.Identificador,
                }"
                style="min-width: unset"
                @click="
                  inlineFilters.Identificador == '=' + item.Identificador
                    ? $delete(inlineFilters, 'Identificador')
                    : $set(
                        inlineFilters,
                        'Identificador',
                        '=' + item.Identificador
                      )
                "
                >{{ item.iniciales }}</v-btn
              >
            </template>
            {{ item.denominacion }}
          </v-tooltip>
            <v-tooltip v-if="item.situacionContrato != 'Activado'" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="getSituacionIcon(item.situacionContrato).color"
                  right
                  >{{ getSituacionIcon(item.situacionContrato).icon }}</v-icon
                >
              </template>
              Contrato {{ item.situacionContrato }}
            </v-tooltip>
            <v-tooltip v-if="item.reenganchado" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="getSituacionIcon('Reenganchado').color"
                  right
                  >{{getSituacionIcon('Reenganchado').icon}}</v-icon
                >
              </template>
              Reenganchado
            </v-tooltip>
            <v-tooltip v-if="item.CESCE" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="info darken-2"
                  right
                  @click="
                    inlineFilters.CESCE == item.CESCE
                    ? $delete(inlineFilters, 'CESCE')
                    : $set( inlineFilters, 'CESCE', item.CESCE )
                  "
                  >mdi-sale</v-icon
                >
              </template>
              Está en CESCE
            </v-tooltip>
            <v-tooltip v-if="item.CESCE_siniestro" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="info darken-1"
                  right
                  @click="
                    inlineFilters.CESCE_siniestro == item.CESCE_siniestro
                    ? $delete(inlineFilters, 'CESCE_siniestro')
                    : $set( inlineFilters, 'CESCE_siniestro', item.CESCE_siniestro )
                  "
                  >mdi-shield-lock</v-icon
                >
              </template>
              Siniestro en CESCE
            </v-tooltip>
            <v-tooltip v-if="item.dptoJuridico" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="error darken-2"
                  right
                  @click="
                    inlineFilters.dptoJuridico == item.dptoJuridico
                    ? $delete(inlineFilters, 'dptoJuridico')
                    : $set( inlineFilters, 'dptoJuridico', item.dptoJuridico )
                  "
                  >mdi-gavel</v-icon
                >
              </template>
              Enviado a monitorio
            </v-tooltip>
            <v-tooltip v-if="item.envio_gesico" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="info lighten-2"
                  right
                  @click="
                    inlineFilters.envio_gesico == item.envio_gesico
                    ? $delete(inlineFilters, 'envio_gesico')
                    : $set( inlineFilters, 'envio_gesico', item.envio_gesico )
                  "
                  >mdi-police-badge-outline</v-icon
                >
              </template>
              Enviado a Gesico
            </v-tooltip>
            <v-tooltip v-if="item.corteEn" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" right color="error darken-2"
                  >mdi-content-cut</v-icon
                >
              </template>
              Corte {{ item.corteEn.toLowerCase() }}
            </v-tooltip>

            <div class="d-flex flex-column ml-1">
              <v-icon
                v-if="item.envioCarta1"
                :color="getSituacionIcon('1ª Carta').color"
                :disabled="!Number(item.carta1)"
                x-small
                >mdi-email-send-outline</v-icon
              >
              <v-icon
                v-if="item.envioCarta2"
                :color="getSituacionIcon('2ª Carta').color"
                :disabled="!Number(item.carta2)"
                x-small
                >mdi-email-send</v-icon
              >
              <v-icon
                v-if="item.envioCarta3"
                :color="getSituacionIcon('Carta de Corte').color"
                :disabled="!Number(item.cartaCorte)"
                x-small
                >mdi-content-cut</v-icon
              >
            </div>

            <v-icon
              v-if="
                Date.now() - parseDate(item.FechaImpagado, true) <
                  1000 * 3600 * 24 * 1.5
              "
              >mdi-circle-small</v-icon
            >
          </div>
        </template>

        <template v-slot:item.diasDesdeEmision="{ item }">
          <v-chip
            outlined
            v-if="item.diasDesdeEmision >= 0"
            v-text="`${item.diasDesdeEmision} días`"
            small
            :class="['v-chip--active']"
            :color="colorChip(item.diasDesdeEmision, item)"
          />
          <!-- - {{ item.diasDesdeVencimiento }} días -->
        </template>

        <template v-slot:item.diasDesdeImpagado="{ item }">
          <v-chip
            outlined
            v-if="item.diasDesdeImpagado >= 0"
            v-text="`${item.diasDesdeImpagado} días`"
            small
            :class="['v-chip--active']"
            :color="colorChip(item.diasDesdeImpagado, item)"
          />
          <!-- - {{ item.diasDesdeVencimiento }} días -->
        </template>

        <template v-slot:item.estadoPropio="{ item }">
          <status-chip v-if="item.estadoPropio" :value="item.estadoPropio" 
            @click.stop="$set(inlineFilters, 'estadoPropio', inlineFilters.estadoPropio ? null : item.estadoPropio)"
           />
          <v-tooltip
            v-if="
              item.estadoPropioVencimiento &&
                parseDate(item.estadoPropioVencimiento, true) <= Date.now()
            "
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="warning" right small
                >mdi-alert-outline</v-icon
              >
            </template>
            Fecha superada
          </v-tooltip>
        </template>

        <template v-slot:item.empresa="{ item }">
          <empresa-chip small v-if="item.empresa" :value="item.empresa" />
        </template>

        <template v-slot:item.diferenciaVenImp="{ item }">
          {{ item.diasDesdeVencimiento - item.diasDesdeImpagado }}
        </template>

        <template v-slot:item.estadoPropioFecha="{ item }">
          <span
            v-if="item.estadoPropioFecha"
            v-text="timeAgo(item.estadoPropioFecha)"
          />
        </template>

        <template v-slot:item.CanalAgente="{ item }">
          <strong>{{ item.CanalAgente }}</strong>
        </template>

        <template v-slot:item.FechaFactura="{ item }">
          <span v-text="parseDate(item.FechaFactura)"></span>
        </template>
        <template v-slot:item.FechaCobro="{ item }">
          <span v-text="parseDate(item.FechaCobro)"></span>
        </template>
        <template v-slot:item.FechaComienzoRemesa="{ item }">
          <v-icon
            v-if="
              item.estado == 'Pendiente' &&
                parseDate(item.FechaTransferencia, true) < Date.now()
            "
            color="warning"
            small
            left
            >mdi-alert-outline</v-icon
          >
          <span v-text="parseDate(item.FechaComienzoRemesa)"></span>
        </template>
        <template v-slot:item.FechaTransferencia="{ item }">
          <v-icon
            v-if="
              item.estado == 'Pendiente' &&
                parseDate(item.FechaTransferencia, true) < Date.now()
            "
            color="warning"
            small
            left
            >mdi-alert-outline</v-icon
          >
          <span v-text="parseDate(item.FechaTransferencia)"></span>
        </template>
        <template v-slot:item.FechaVencimiento="{ item }">
          <span v-text="parseDate(item.FechaVencimiento)"></span>
        </template>
        <template v-slot:item.estadoPropioVencimiento="{ item }">
          <span v-text="parseDate(item.estadoPropioVencimiento)"></span>
        </template>
        <template v-slot:item.fechaCreacion="{ item }">
          <span v-text="parseDate(item.fechaCreacion)"></span>
        </template>
        <template v-slot:item.FechaLecturaAnteriorXML="{ item }">
          <span v-text="parseDate(item.FechaLecturaAnteriorXML)"></span>
        </template>
        <template v-slot:item.FechaLecturaActualXML="{ item }">
          <span v-text="parseDate(item.FechaLecturaActualXML)"></span>
        </template>
        <template v-slot:item.FechaImpagado="{ item }">
          <span v-text="parseDate(item.FechaImpagado)"></span>
        </template>
        <template v-slot:item.fechaUltimaFraccion="{ item }">
          <span v-text="parseDate(item.fechaUltimaFraccion)"></span>
        </template>
        <template v-slot:item.envioCarta1="{ item }">
          <span v-text="parseDate(item.envioCarta1)"></span>
        </template>
        <template v-slot:item.envioCarta2="{ item }">
          <span v-text="parseDate(item.envioCarta2)"></span>
        </template>
        <template v-slot:item.envioCarta3="{ item }">
          <span v-text="parseDate(item.envioCarta3)"></span>
        </template>
        <template v-slot:item.fechaCorteEstimada="{ item }">
          <span v-text="parseDate(item.fechaCorteEstimada)"></span>
        </template>

        <template v-slot:item.email="{ item }">
          <table-button
            v-if="item.email"
            color="secondary"
            tooltip="Enviar correo"
            :href="`mailto:${item.email}`"
            icon-left="mdi-email-outline"
            >{{ item.email }}</table-button
          >
        </template>

        <template v-slot:item.movil="{ item }">
          <span>{{ item.movil | phone }}</span>
        </template>
        <template v-slot:item.fijo="{ item }">
          <span>{{ item.fijo | phone }}</span>
        </template>

        <template v-slot:item.commercialRiskCoverPct="{ item }">
          <span v-if="item.commercialRiskCoverPct" v-text="item.commercialRiskCoverPct + ' %'" />
        </template>
        <template v-slot:item.ImporteNominal="{ item }">
          <span v-text="parseFloat(item.ImporteNominal).toFixed(2) + ' €'" />
        </template>
        <template v-slot:item.ImporteGasto="{ item }">
          <span v-text="`${parseFloat(item.ImporteGasto).toFixed(2)} €`" />
        </template>
        <template v-slot:item.ImporteTotal="{ item }">
          <span
            class="font-weight-bold"
            v-text="`${parseFloat(item.ImporteTotal).toFixed(2)} €`"
          />
        </template>

        <template v-slot:item.tarifa="{ item }">
          <v-chip v-show="item.tarifa" label small v-text="item.tarifa" />
        </template>

        <template v-slot:item.carta1Bool="{ item }">
          <div class="d-flex text-caption">
            <div style="height: unset" v-if="item.envioCarta1">
              <v-icon
                left
                small
                v-if="item.envioCarta1 != null && !item.carta1"
                color="error"
                >mdi-email-remove-outline</v-icon
              >
              <v-icon left small v-else color="success"
                >mdi-email-fast-outline</v-icon
              >
              {{ timeAgo(item.carta1 || item.envioCarta1, false) }}
            </div>
            <v-icon
              left
              small
              v-else-if="item.envioCarta1 === null"
              color="warning"
              >mdi-minus-circle-outline</v-icon
            >
          </div>
        </template>

        <template v-slot:item.carta2Bool="{ item }">
          <div class="d-flex text-caption">
            <div style="height: unset" v-if="item.envioCarta2">
              <v-icon left small v-if="!item.carta2" color="error"
                >mdi-email-remove-outline</v-icon
              >
              <v-icon left small v-else color="success"
                >mdi-email-fast-outline</v-icon
              >
              {{ timeAgo(item.carta2 || item.envioCarta2, false) }}
            </div>
						 <v-icon
              left
              small
              v-else-if="item.envioCarta2 === null"
              color="warning"
              >mdi-minus-circle-outline</v-icon
            >
          </div>
        </template>

        <template v-slot:item.cartaCorteBool="{ item }">
          <div class="d-flex text-caption">
            <div style="height: unset" v-if="item.envioCarta3">
              <v-icon left small v-if="!item.cartaCorte" color="error"
                >mdi-email-remove-outline</v-icon
              >
              <v-icon left small v-else color="success"
                >mdi-email-fast-outline</v-icon
              >
              {{ timeAgo(item.cartaCorte || item.envioCarta3, false) }}
            </div>
						 <v-icon
              left
              small
              v-else-if="item.envioCarta3 === null"
              color="warning"
              >mdi-minus-circle-outline</v-icon
            >
          </div>
        </template>

        <template v-slot:item.esEmpresa="{ item }">
          <v-icon small v-if="item.esEmpresa == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.dptoJuridico="{ item }">
          <v-icon small v-if="item.dptoJuridico == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="error darken-2"
            >mdi-gavel</v-icon
          >
        </template>

        <template v-slot:item.CESCE="{ item }">
          <v-icon small v-if="item.CESCE == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="info darken-2"
            >mdi-sale</v-icon
          >
        </template>

        <template v-slot:item.CESCE_siniestro="{ item }">
          <v-icon small v-if="item.CESCE_siniestro == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="info darken-1"
            >mdi-shield-lock</v-icon
          >
        </template>

        <template v-slot:item.envio_gesico="{ item }">
          <v-icon small v-if="item.envio_gesico == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="info darken-1"
            >mdi-police-badge-outline</v-icon
          >
        </template>

        <template v-slot:item.estimada="{ item }">
          <v-icon small v-if="item.estimada == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.amenazaCorteBool="{ item }">
          <v-icon small v-if="item.amenazaCorteBool == '0'" color
            >mdi-minus-circle-outline</v-icon
          >
          <v-icon small v-else color="error darken-2">mdi-content-cut</v-icon>
        </template>

        <template v-slot:item.superior10KW="{ item }">
          <v-icon small v-if="item.superior10KW == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.denominacion="{ item }">
          <span v-if="item.RazonSocialTitular" class="font-weight-bold">
            {{ item.RazonSocialTitular }}
          </span>
          <template v-else>
            <span>{{ item.Apellido1Titular + "&nbsp;" }}</span>
            <span>{{ item.Apellido2Titular }}</span>
            <span class="font-weight-bold">
              {{ "&nbsp;" + item.NombreTitular }}
            </span>
          </template>
        </template>

        <template v-slot:item.NumeroFactura="{ item }">
          <table-button
              :tooltip="`Ver PDF factura ${ item.estimada ? '(estimada)' : '' }`"
              :disabled="!!pdfId || !item.IdDocumento"
              color="secondary"
              @click.stop="verPDFfactura(item.IdDocumento)"
              :loading="pdfId == item.IdDocumento && pdfId !== null"
              :icon-right="
                item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'
              "
            >
              {{ item.SerieFactura }}
              {{ item.NumeroFactura }}
            </table-button>
        </template>

        <template v-slot:item.NumeroFacturaRoot="{ item }">
            <table-button
              :tooltip="`Ver PDF factura ${ item.estimada ? '(estimada)' : '' }`"
              :disabled="!!pdfId || !item.IdDocumento"
              color="secondary"
              @click.stop="verPDFfactura(item.IdDocumento)"
              :loading="pdfId == item.IdDocumento && pdfId !== null"
              :icon-right="
                item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'
              "
            >
              {{ item.SerieFacturaRoot }}
              {{ item.NumeroFacturaRoot }}
            </table-button>
        </template>

        <template v-slot:item.situacionContrato="{ item }">
          <status-chip :value="item.situacionContrato" />
        </template>

        <template v-slot:item.NumeroFraccion="{ item }">
          <div class="d-flex" v-if="item.NumeroFraccion">
            <v-icon
              :key="i"
              v-for="i in Number(item.NFracciones)"
              :color="
                [
                  'primary darken-3',
                  'primary darken-2',
                  'primary darken-1',
                  'primary',
                ][i - 1]
              "
            >
              {{
                Number(item.NumeroFraccion) >= i
                  ? "mdi-circle"
                  : "mdi-circle-outline"
              }}
            </v-icon>
          </div>
          <status-chip
            v-else-if="Number(item.NFracciones)"
            small
            :value="`Fraccionado ${item.NFracciones} Pagos`"
          ></status-chip>
        </template>

        <template v-slot:item.CodigoContrato="{ item }">
          <table-button
            color="secondary"
            tooltip="Detalles del contrato"
            :to="
              `/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`
            "
            :icon-left="
              item.CodigoContrato > 0 ? 'mdi-lightning-bolt' : 'mdi-fire'
            "
            >{{ Math.abs(Number(item.CodigoContrato)) }}</table-button
          >
        </template>

        <template v-slot:item.Identificador="{ item }">
          <table-button
            color="secondary"
            tooltip="Detalles del cliente"
            :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
            >{{ item.Identificador }}</table-button
          >
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="
                  {
                    tab = 0;
                    detailsId = item.IdCarteraCobro;
                  }
                "
                icon
                color="secondary"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            Ver detalles
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                color="secondary"
                :disabled="!item.tieneMensajes"
                @click="getMessages(item.IdCarteraCobro, item.IdCliente)"
              >
                <v-icon>mdi-note-multiple-outline</v-icon>
              </v-btn>
            </template>
            Ver último mensaje
          </v-tooltip>
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="secondary"
                icon
                :disabled="!!item.avisoAutomatico || item.email == null || item.IdDocumento == null"
                :loading="sendingAvisoAutomatico == item.IdCarteraCobro"
                @click.stop="sendAvisoAutomatico(item.IdCarteraCobro, item.ImporteTotal)"
              >
                <v-icon>mdi-email-fast-outline</v-icon>
              </v-btn>
            </template>
            Aviso automático
          </v-tooltip>
        </template>

      </v-data-table>
    </v-card>

    <div class="d-flex flex-wrap mt-4 align-center">
      <strong class="mr-2">
        Total importes
        {{
          selected.length == 0
            ? "registros filtrados"
            : "registros seleccionados"
        }}
        :
      </strong>
      {{ acumuladoImpagados }}
      <v-btn
        text
        color="primary"
        class="ml-2"
        small
        :disabled="selected.length == 0"
        @click="selected = []"
        >Deseleccionar todos ({{ selected.length }})</v-btn
      >

      <v-spacer />

        <v-btn
          color="primary"
          text
          :disabled="selected.length <= 0"
          @click.stop="selected.forEach(({ IdDocumento }) => verPDFfactura(IdDocumento, false))"
          class="mr-2"
        >
          <v-icon left>mdi-file-download-outline</v-icon>Descargar PDFs
        </v-btn>

      <v-dialog
      persistent
      max-width="900px"
      width="100%"
      v-model="pagoTarjeta"
    >

      <template v-slot:activator="{on, attrs}">
        <v-btn
          v-if="menuTab != 'historico'"
          color="primary"
          :disabled="selected.length > 0 && selected.some(f => f.IdCliente !== selected[0].IdCliente)"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-credit-card-outline</v-icon>Generar pago por link
        </v-btn>
      </template>

      <link-pago-tarjeta
        v-if="pagoTarjeta"
        :key="String(pagoTarjeta + selected.length)"
        :impagados="selected"
        :custom="selected.length == 0"
        @close="pagoTarjeta = false"
      />
    </v-dialog>

      

      <!-- <v-btn
        text
        color="primary"
        small
        @click="
          Object.entries(colors).forEach(([id]) =>
            changeRowBackground(id, null)
          )
        "
      >
        <v-icon left>mdi-water-minus-outline</v-icon>Eliminar colores
      </v-btn> -->
    </div>

    <div
      v-if="$root.acceso('CONTROL_IMPAGADOS')"
      class="d-flex flex-wrap mt-4"
      style="gap: 16px"
    >
      <v-btn text color="primary" @click="exportarTodos">
        <v-icon left>mdi-download</v-icon>Exportar todos
      </v-btn>
    </div>

    <v-dialog
      max-width="900"
      v-model="drawerMensajes.show"
      class="pa-2"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar color="primary" dense>
          <v-toolbar-title>
            Mensajes impago + cliente
          </v-toolbar-title>
        </v-toolbar>
          <v-card-text style="max-height: 700px; height: 100%; overflow-y: auto; position: relative; padding: 0px">
            <Chat :key="drawerMensajes.id" no-input :messages="drawerMensajes.msg" :item-key="drawerMensajes.id" key-name="IdCarteraCobro" />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click.stop="detailsId = drawerMensajes.id; drawerMensajes.show = false">
              <v-icon left>mdi-eye</v-icon>
              Ver detalles
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <ModalDetallesImpagados v-model="detailsId" />
    </v-dialog>

    <v-dialog persistent max-width="600px" width="100%" v-model="cambiarEstado">
      <cambiar-estado
        :key="cambiarEstado"
        @reload="getImpagados"
        :impagados="selected.map((c) => c.IdCarteraCobro)"
        @close="cambiarEstado = false"
      />
    </v-dialog>

    

    <component :is="contextMenuComp" />
  </v-container>
</template>

<script>
import { getSituacionIcon, perColumnFilter, parseDate, downloadFile, timeAgo, jsonToCsv, } from "@/utils/index.js";
import { parseImpagados } from "../services/parseImpagados";
import contextMenu from "../services/contextMenu";

export default {
  components: {
    Chat: () => import("@/components/Chat/Chat.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    BtnFiltros: () => import("@/components/BtnFiltros.vue"),
    EmpresaChip: () => import("@/components/EmpresaChip.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    TableButton: () => import("@/components/TableButton.vue"),
    MensajeTimeline: () => import("@/components/MensajeTimeline.vue"),
    ModalDetallesImpagados: () => import("../components/ModalDetallesImpagados.vue"),
    ImpagadosHeatGrid: () => import("../components/ImpagadosHeatGrid.vue"),
    CambiarEstado: () => import("../components/CambiarEstado.vue"),
    LinkPagoTarjeta: () => import("../components/LinkStripe.vue"),
    SolicitudesCorte: () => import("../components/SolicitudesCorte.vue"),
    SolicitudesReenganche: () => import("../components/SolicitudesReenganche.vue"),
  },
  props: ["codigoContrato"],
  computed: {
    acumuladoImpagados() {
      if (this.selected.length) {
        return (
          parseFloat(
            this.selected.reduce((a, c) => a + Number(c.ImporteTotal), 0)
          ).toFixed(2) + " €"
        );
      } else {
        // return parseFloat(this.impagados.reduce((a, c) => a + Number(c.ImporteTotal), 0)).toFixed(2) + ' €'
        const getDatatype = (key) =>
          (this.impagadosHeader.find((h) => h.value == key) || {}).dataType;

        return (
          parseFloat(
            this.impagadosFiltrados
              .filter((con) =>
                Object.entries(con).every(([key, value]) =>
                  perColumnFilter(
                    value,
                    this.inlineFilters[key],
                    getDatatype(key)
                  )
                )
              )
              .map((i) => Number(i.ImporteTotal))
              .reduce((a, c) => a + c, 0)
          ).toFixed(2) + " €"
        );
      }
    },
    headersFiltrados() {
      return this.impagadosHeader.filter(
        (h) =>
          (h.tabFilter || []).includes(this.menuTab) ||
          h.tabFilter == null ||
          h.tabFilter == undefined
      );
    },
    impagadosFiltrados() {
      return this.menuTab == "actual"
        ? this.impagados.filter((imp) => {
            // const [key, value] = Object.entries(this.estadosFiltros)
            for (let [key, value] of Object.entries(this.estadosFiltros))
              if (imp.estadoPropio == key) return value;
            return true;
          })
        : this.impagados;
    },
  },
  data() {
    return {
      menuTab: "actual",
      showHeatMap: false,
      loading: true,
      page: 2,
      tab: 0,
      selected: [],
      page: 1,
      detailsId: null,
      sendingAvisoAutomatico: null,
      contextMenuComp: null,
      llamadasId: null,
      cambiarEstado: null,
      pdfId: false,
      impagados: [],
      solicitudesCorte: {
        show: false,
        sol: [],
      },
      solicitudesReenganche: {
        show: false,
        sol: [],
      },
      impagadosHeader: [
        { text: "Estado", value: "estado", dataType: "select", cellClass: "pa-2 text-no-wrap p-relative", tabFilter: ["historico", "gesico", "fracTransferencia", "frac"] },
        { text: "", value: "info", sortable: false, filterable: false },
        { text: "NºImp", value: "NImpagadosCliente" },
        { text: "Total", value: "ImporteTotal" },
        { text: "Contrato", value: "CodigoContrato" },
        { text: "Identificador", value: "Identificador" },
        { text: "Cliente", value: "denominacion" },
        { text: "Fecha Cobro", value: "FechaCobro", dataType: "date", tabFilter: ["historico"], },
        { text: "Fecha Transferencia", value: "FechaTransferencia", dataType: "date", tabFilter: ["fracTransferencia"], },
        { text: "Fecha Remesado", value: "FechaComienzoRemesa", dataType: "date", tabFilter: ["historico", 'frac'], },
        { text: "Final Fraccionamiento", value: "fechaUltimaFraccion", tabFilter: ["frac"], dataType: "date", },
        { text: "Días Emision", value: "diasDesdeEmision", tabFilter: ["actual", "gesico"], },
        { text: "Días Imp.", value: "diasDesdeImpagado" },
        { text: "Estado (propio)", value: "estadoPropio", dataType: "select", tabFilter: ["actual", "cesce", "monitorio", "historico"], },
        { text: "Último cambio estado", value: "estadoPropioFecha", dataType: "date", tabFilter: ["actual", "cesce", "monitorio", "historico"], },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false, tabFilter: ["actual", "gesico", "historico", "fracTransferencia"], },
        { text: "> 10 KW", value: "superior10KW", align: "center", dataType: "bool", },
        { text: "Fraccion", value: "NumeroFraccion" },
        { text: "NºDoc Actual", value: "NumeroFactura" },
        { text: "NºDoc Original", value: "NumeroFacturaRoot" },
        // { text: "1ª Carta", value: "carta1Bool", dataType: "bool", tabFilter: ["actual"], },
        // { text: "2ª Carta", value: "carta2Bool", dataType: "bool", tabFilter: ["actual"], },
        // { text: "Corte", value: "cartaCorteBool", dataType: "bool", tabFilter: ["actual"], },
        { text: "Portal", value: "empresa", dataType: "select", acceso: "VER_TODOS_CONTRATOS", },
        { text: "Agente/Canal", value: "CanalAgente" },
        { text: "SubAgente", value: "SubAgente" },
        { text: "Dpto. Jurídico", value: "dptoJuridico", dataType: "bool", align: "center", },
        { text: "CESCE", value: "CESCE", acceso: 'CONTROL_MONITORIO_IMPAGADOS', dataType: 'bool', align: 'center' },
        { text: "Siniestro CESCE", value: "CESCE_siniestro", acceso: 'CONTROL_MONITORIO_IMPAGADOS', dataType: 'bool', align: 'center' },
        { text: "Envio Gesico", value: "envio_gesico", acceso: 'CONTROL_MONITORIO_IMPAGADOS', dataType: 'bool', align: 'center' },
        // { text: "Límite solicitado", value: "creditLimitRequested", acceso: 'CONTROL_MONITORIO_IMPAGADOS' },
        // { text: "Límite concedido", value: "creditLimitGranted", acceso: 'CONTROL_MONITORIO_IMPAGADOS' },
        // { text: "Porcentaje cobertura", value: "commercialRiskCoverPct", acceso: 'CONTROL_MONITORIO_IMPAGADOS' },
        { text: "Corte estimado", value: "fechaCorteEstimada", dataType: "date", },
        { text: "Amenaza Corte", value: "amenazaCorteBool", dataType: "bool", align: "center", },
        { text: "Estimada", value: "estimada", dataType: "bool", align: "center" },
        { text: "Situacion Contrato", value: "situacionContrato", dataType: "select", },
        { text: "Empresa", value: "esEmpresa", dataType: "bool", align: "center", },
        { text: "Movil", value: "movil" },
        { text: "Fijo", value: "fijo" },
        { text: "Email", value: "email" },
        // { text: "Fecha Impagado", value: "FechaImpagado", dataType: "date" },
        // { text: "Fecha Vencimiento original", value: "FechaVencimiento", dataType: "date", },
        // { text: "Fecha Vencimiento (propio)", value: "estadoPropioVencimiento", dataType: "date", },
        // { text: "Fecha Transferencia", value: "FechaTransferencia", dataType: "date", tabFilter: ["actual"], },
        // { text: "Tipo Cobro", value: "TextoTipoCobro", dataType: "select" },
        // { text: "Banco Cobro", value: "BancoCobro", dataType: "select" },
        // { text: "Banco Cargo", value: "BancoCargo", dataType: "select" },
        { text: "Canal", value: "Canal", dataType: "select" },
        // { text: "Fecha factura", value: "FechaFactura", dataType: "date" },
        // { text: "Motivo", value: "motivoImpagado" },
        // { text: "Tarifa", value: "tarifa", align: "center", dataType: "select", },
      ]
        .filter((h) => h.acceso == null || this.$root.acceso(h.acceso))
        .map((header) => ({
          class: "text-no-wrap sticky-header",
          cellClass: "pa-1 text-no-wrap",
          filter: (value) => perColumnFilter( value, this.inlineFilters[header.value], header.dataType ),
          dataType: "text",
          ...header,
        })),

      estadosFiltros: {
        Abonado: false,
        "Pagado Tarjeta": false,
        "Pago Tarjeta Rechazado": true,
        'Pendiente Justificante' : false,
      },

      inlineFilters: Object.keys(this.$route.query).length > 0 ? Object.fromEntries( Object.entries(this.$route.query).map(([key, value]) => [ key, "=" + value, ]) ) : {},

      comboboxes: {},
      loadingPDF: null,
      pagoTarjeta: false,

      drawerMensajes: { show: false, msg: [] },
    };
  },
  methods: {
    getSituacionIcon,
    parseDate,
    timeAgo,
    async getSolicitudesCorte() {
      const { data: solicitudes } = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/solicitudesCorte.php`,
        method: "GET",
        params: { token: this.$store.getters.getJwtEmpresa },
      });
      
      this.$set(this.solicitudesCorte, 'sol', solicitudes.map((sol) => {
        if (new Date(sol.FechaPrevistaActivacion).getTime() > 0) {
          sol.fecha = parseDate(new Date(sol.FechaPrevistaActivacion), false, true, true)
        } else {
          sol.fecha = null
        }
        sol.fechaEfecto = timeAgo(parseDate(sol.FechaPrevistaActivacion, true));
        return sol;
      }));
    },
    async getSolicitudesReenganche() {
      const { data: solicitudes } = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/solicitudesReenganche.php`,
        method: "GET",
        params: { token: this.$store.getters.getJwtEmpresa },
      });

      this.$set(this.solicitudesReenganche, 'sol', solicitudes.map((sol) => {
        sol.fecha = parseDate(new Date(sol.FechaPrevistaActivacion), false, true, true)
        return sol;
      }));
    },
    async getImpagados() {
      this.loading = true;
      const { data: impagados } = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
        method: "GET",
        params: {
          token: this.$store.getters.getJwtEmpresa,
          menuTab: this.menuTab,
        },
      });
      this.impagados = parseImpagados(impagados);

      this.loading = false;
    },
    // [ Math.floor( Math.min(item.NImpagadosCliente-1, 8) / 2) ]
    getNImpagadosColor(arr, v, step = 2) {
      return arr[Math.floor(Math.min(v - 1, (arr.length - 1) * step) / step)];
    },
    getClienteColor(arr, v, step = 2) {
      return arr[Math.floor(Math.min(v - 1, (arr.length - 1) * step) / step)];
    },
    colorChip(dias, { envioCarta1, envioCarta2, envioCarta3 }) {
      if (Date.now() >= parseDate(envioCarta3, true)) return "error";
      if (Date.now() >= parseDate(envioCarta2, true)) return "deep-orange";
      if (Date.now() >= parseDate(envioCarta1, true)) return "warning";
    },
    verPDFfactura(idDocumento, open = true) {
      this.pdfId = idDocumento;
      let str = this.$store.getters.getDatosEmpresa;
      const { IdUsuario } = str.iss;
      const IdEmpresa = this.$store.getters.getIdEmpresa;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
        responseType: "blob",
      })
        .then((res) => {
          const {SerieFacturaRoot, NumeroFacturaRoot, denominacion} = this.impagadosFiltrados.find(x => x.IdDocumento == idDocumento)
          let pdf = new File(
            [res.data],
            `${SerieFacturaRoot}-${NumeroFacturaRoot}-${denominacion}.pdf`,
            {
              type: "application/pdf",
            }
          );
          downloadFile(pdf, open);
          this.pdfId = null;
        })
        .catch((err) => {
          this.$root.$emit("snack", "Ha ocurrido un error");
          this.pdfId = null;
        });
    },
    async getMessages(IdCarteraCobro, IdCliente) {
      let { data: messages } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
        params: {
          IdCarteraCobro,
          IdCliente,
          token: this.$store.getters.getJwtEmpresa,
        },
      });

      this.drawerMensajes.msg = (messages);
      this.drawerMensajes.show = true;
      this.drawerMensajes.id = IdCarteraCobro;
    },
    sendAvisoAutomatico(IdCarteraCobro, importe) {
      if (importe > 1500 && !confirm('¿El importe es muy grande, seguro que quieres enviar este correo?')) {
        return;
      }

      this.sendingAvisoAutomatico = IdCarteraCobro
      axios({url: `${process.env.VUE_APP_OUR_API_URL}/avisoImpagoAutomatico.php`, params: { IdCarteraCobro }}).then((res) => {
          this.$root.$emit('snack', 'Aviso enviado con éxito')
      }).catch((err) => {
        console.error(err)
        this.$root.$emit('snack', 'No se ha podido enviar el aviso')
      }).finally(() => {
      this.sendingAvisoAutomatico = null
      })
    },
    exportarTodos(items) {
      const getDatatype = (key) =>
        (this.impagadosHeader.find((h) => h.value == key) || {}).dataType;

      if (!Array.isArray(items))
        items = this.impagadosFiltrados.filter((con) =>
          Object.entries(con).every(([key, value]) =>
            perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
          )
        );

      const csvHeaders = [
        { key: "FechaFactura", dataType: "date" },
        { key: "CodigoContrato" },
        { key: "situacionContrato" },
        { key: "FechaVencimiento", dataType: "date" },
        { key: "IBAN" },
        { key: "ImporteTotal", dataType: "number" },
        { key: "motivoImpagado" },
        { key: "fechaCorteEstimada", alias: 'Corte estimado', dataType: "date" },
        { key: "FechaImpagado", dataType: "date" },
        { key: "SerieFactura", alias: "Serie Factura" },
        { key: "NumeroFactura", alias: "Numero Factura" },
        { key: "Canal" },
        { key: "TextoTipoCobro", alias: "Tipo Cobro" },
        { key: "SerieFacturaRoot", alias: "Serie Factura Original" },
        { key: "NumeroFacturaRoot", alias: "Numero Factura Original" },
        { key: "denominacion", alias: "Cliente" },
        // { key: 'RazonSocialTitular' },
        // { key: 'NombreTitular' },
        // { key: 'Apellido1Titular' },
        // { key: 'Apellido2Titular' },
        { key: "ultimoMensaje", alias: "Último mensaje", dataType: "html" },
        { key: "Identificador" },
        { key: "estadoPropio" },
        { key: "estadoPropioFecha", dataType: "date" },
        { key: "estadoPropioVencimiento", dataType: "date" },
        { key: "CanalAgente" },
        { key: "SubAgente" },
        { key: "envioCarta1", dataType: "date" },
        { key: "envioCarta2", dataType: "date" },
        { key: "envioCarta3", dataType: "date" },
      ];

      jsonToCsv(
        items,
        csvHeaders,
        "CONTROL_IMPAGADOS_" + parseDate(Date.now(), false, false)
      );
    },
    contextMenuImp(event, item) {
      contextMenu(event, item.item, this, item.isSelected, item.select);
    },
  },
  mounted() {
    this.getSolicitudesCorte();
    this.getSolicitudesReenganche();
  },
  watch: {
    menuTab: {
      handler() {
        this.impagados = [];
        this.getImpagados();
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.detailsId != null) {
      this.detailsId = null;
      next(false);
      return
    }
    next()
  },
};
</script>

<style lang="scss">
.row_impagado {
  // transition: background .2s;
}
.row_impagado-baja {
  color: #ff3c00 !important;
}
.row_impagado-tarjeta {
  color: var(--v-success-darken1) !important;
}
.row_impagado-abonado {
  color: var(--v-success-base) !important;
}

span.ripple {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  height: 0;
  opacity: 1;
  position: absolute;
  transform: scale(0);
  width: 0;
  will-change: opacity, transform;
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
@-moz-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-transition-property: none;
    -moz-transition-property: none;
    transition-property: none;
  }
}

.ripple {
  animation: 0.5s linear;
}

.p-relative {
  position: relative;
}
</style>
